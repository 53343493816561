"use client";

import Link from "next/link";
import { useRouter, usePathname } from "next/navigation";

import version from "../version.json";

const Sidebar = ({ isOpen }: { isOpen: boolean }) => {
  const router = useRouter();
  const pathname = usePathname();

  return (
    <div
      className={`h-screen fixed top-0 left-0 z-10 w-64 bg-gray-800 text-white flex flex-col transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      {/* Logo or App Name */}
      <div className="text-2xl font-bold p-4">FitnessApp</div>
      {/* Navigation Links */}
      <nav>
        <ul>
          {[
            "goal",
            "pace",
            // "eat",
            // "neat",
            // "bmr",
            // "diet",
            "balance",
            "calendar",
            "habits",
            "fitbit",
            // "sleep",
            // "activity",
          ].map((feature) => (
            <li
              key={feature}
              className={`${
                pathname === `/${feature}` ? "bg-gray-700" : ""
              } p-2`}
            >
              <Link href={`/${feature}`}>
                {feature.charAt(0).toUpperCase() + feature.slice(1)}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="absolute bottom-0 w-full p-4 text-center text-sm text-gray-400">
        Version: {version.hash}
      </div>
    </div>
  );
};

export default Sidebar;
